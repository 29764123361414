<template>
  <section class="hero is-fullheight loginbg">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="hero-body">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <div class="has-text-centered is-size-3 has-text-light mb-4">
                <strong class="has-text-light">Gifomany</strong> Admin
              </div>
              <div
                class="box animated"
                v-bind:class="{
                  shake: form.error,
                  intensifies: form.explode,
                  red: form.explode
                }"
                @keyup.enter.exact="onSubmit()"
              >
                <b-field
                  label="Email"
                  :type="form.email.status"
                  :message="form.email.message"
                >
                  <b-input
                    type="email"
                    v-model="form.email.data"
                    placeholder="Your email"
                    required
                  ></b-input>
                </b-field>

                <b-field
                  label="Password"
                  :type="form.pass.status"
                  :message="form.pass.message"
                >
                  <b-input
                    type="password"
                    v-model="form.pass.data"
                    password-reveal
                    placeholder="Your password"
                    required
                  ></b-input>
                </b-field>

                <b-message :type="form.status" v-if="form.result">
                  {{ form.message }}
                </b-message>

                <div class="field has-text-centered">
                  <button class="button is-primary" @click="onSubmit()">
                    Log in
                  </button>
                </div>
              </div>
              <div class="has-text-centered">
                <small class="has-text-ddlight"
                  >{{ currentYear }} &copy; Syntho.net | All Rights Reserved.</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "login",
  data() {
    return {
      form: {
        email: {
          data: "",
          status: "",
          message: ""
        },
        pass: {
          data: "",
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      },
      isLoading: false,
      currentYear: new Date().getFullYear()
    }
  },
  beforeMount() {
    document.body.className = document.body.className.replace(
      "has-navbar-fixed-top",
      ""
    );
  },
  mounted() {
    if (localStorage.gshopEmail) {
      this.form.email.data = localStorage.gshopEmail;
    }
  },
  methods: {
    onSubmit() {
      if (this.emailCheck("email") & this.passCheck("pass")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/login.php`, {
            email: this.form.email.data,
            pass: this.form.pass.data
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.$store.commit("loginUser", response.data);
              console.log(response.data.favs);
              //!!!redirect
              this.$router.push({ path: "/dashboard" }).catch(err => {});
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.controll.email.status = "is-danger";
              } else if (response.data.type == "pass") {
                this.form.controll.pass.status = "is-danger";
              } else {
                this.form.controll.email.status = "is-danger";
                this.form.controll.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email address";
        return false;
      } else {
        this.form.email.status = "is-success";
        return true;
      }
    },
    passCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "Please enter a Password";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].status = "is-success";
        return true;
      }
    },
    hasError() {
      if (this.form.countErrors > 2) {
        this.hasMultipleErrors();
        this.form.countErrors = 0;
      } else {
        this.form.error = true;
        setTimeout(() => (this.form.error = false), 1500);
      }
      setTimeout(() => (this.form.submited = false), 1500);
    },
    hasMultipleErrors() {
      this.form.explode = true;
      setTimeout(() => (this.form.explode = false), 2000);
    }
  }
};
</script>
<style scoped>
@import "~@/static/intensifies.css";
.red {
  background: #e40000;
  transition: background 2s;
}
.body.has-navbar-fixed-top,
html.has-navbar-fixed-top {
  padding-top: 0 !important;
}
</style>
