<template>
  <div class="box">
    <div class="level">
      <div class="level-left is-size-4">
        Total Items:
      </div>
      <div class="level-right">         
          <div class="is-size-4">
            <span class="has-text-grey">{{indata.all}}/</span><span class="has-text-primary has-text-weight-bold">{{indata.active}}</span>
          </div>             
      </div>
    </div>
  
  </div>
</template>
<script>

export default {
  name: "TotalItems",
  props:{
    indata: Object
  }
};
</script>

