<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div
        class="modal-card"
        style="width: 400px"
        @keyup.enter.exact="onSubmit()"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Link</p>
        </header>
        <section class="modal-card-body">
          <b-field
            label="Link"
            :type="form.link.status"
            :message="form.link.message"
          >
            <b-input
              type="text"
              v-model="form.link.data"
              placeholder="Partner Link"
              required
              @change="generateSlug()"
            ></b-input>
          </b-field>

          <b-field
            label="Old Link"
          >
            <p class="control">
              <b-button @click="copyToClp(form.oldLink.data)" type="is-info" icon-left="copy" label="Copy" />
            </p>
            <b-input
              type="text"
              v-model="form.oldLink.data"
              disabled
              expanded
            ></b-input>
            <p class="control">
              <b-button @click="open(form.oldLink.data)" type="is-primary" icon-left="external-link-alt" label="Open" />
            </p>
          </b-field>

          <b-message :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
        </section>
        <footer class="modal-card-foot is-justify-content-center">
          <b-button @click="onSubmit()" type="is-primary" icon-left="save">
            Save Link
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapState } from "vuex";
import toast from "@/mixins/toast";
export default {
  name: "EditLink",
  mixins: [toast],
  data() {
    return {
      itemID: null,
      form: {
        link: {
          data: "",
          status: "",
          message: ""
        },
        oldLink: {
          data: "",
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    saveLink(){
      //begin post --------------------------------------------
          this.axios
            .post(`${this.$env.api}/manage_items.php`, {
              token: localStorage.gshopToken,
              action: "updateURL",
              itemID: this.itemID,
              url: this.form.link.data             
            })
            .then(response => {
              this.isLoading = false;
              console.log(response.data);
              if (response.data.success) {
                this.toast(response.data.message, "is-success");
                //this.emit();
                this.$root.$emit("linkUpdated");
                this.resetForm();   
                this.closeModal(); 
              } else {
                this.form.result = true;
                this.form.message = response.data.message;
                this.form.status = "is-danger";
                this.toast(response.data.message, "is-danger");
                console.log(response.data);
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              this.form.result = true;
              this.form.message = "Sorry, something went wrong, please try again.";
            });
    },
    onSubmit() {
      if (this.formCheck("link")) {
        console.log("updating link");
        this.saveLink();   
      } else {
        console.log("error!");
        this.form.result = true;
        this.form.status = "is-danger";
        this.form.message = "Please fill out all required fields." + this.hasImageError;
      }
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].status = "is-success";
        return true;
      }
    },
    closeModal() {           
      this.$store.commit("hideModal", "editLink");
    },
    resetForm(){
      this.form.link.data = "";
      this.form.oldLink.data = "";
      this.form.link.status = "";     
      this.form.link.message = "";
      this.form.result = false;
      this.form.status = "";
      this.form.message = "";
    },
    copyToClp(txt){
      let m = document;
      txt = m.createTextNode(txt);
      let w = window;
      let b = m.body;
      b.appendChild(txt);
      if (b.createTextRange) {
        let d = b.createTextRange();
        d.moveToElementText(txt);
        d.select();
        m.execCommand('copy');
      } else {
        let d = m.createRange();
        let g = w.getSelection;
        d.selectNodeContents(txt);
        g().removeAllRanges();
        g().addRange(d);
        m.execCommand('copy');
        g().removeAllRanges();
      }
      txt.remove();
      this.toast("The link has been copied to your clipboard!", "is-info");
    },
    open(url){
      window.open(url,"_blank");
    }
  },
  computed:{
    ...mapState(["editLinkData"]),
    isActive: {
      get: function() {
        return this.$store.state.modals.editLink;
      },
      // setter
      set: function() {
        this.resetForm();
        this.$store.commit("hideModal", "editLink");
        return false;
      }
    }
  },
  watch:{
    isActive: function(){
      if(this.editLinkData){
        this.form.oldLink.data = this.editLinkData.url;
        this.itemID = this.editLinkData.id;
      }     
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
</style>
