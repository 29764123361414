<template>
  <div class="manage">
      <section class="container">
        <div class="mt-3">
          <div class="card">

            <header class="card-header">
              <div class="card-header-title">
                <i class="fas fa-clipboard-list mr-2"></i> Manage Menu
              </div>
            </header>

            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <div class="is-size-4 mb-3">Menu Grups</div>
                      <ManageGroup />
                    </div>

                  <div class="column">
                    <div class="is-size-4 mb-3">Menu Items</div>
                      <ManageMenu />        
                    </div>
                </div>                
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
</template>

<script>
import ManageGroup from '../components/ManageGroup.vue';
import ManageMenu from '../components/ManageMenu.vue';
export default {
  name: 'MenuEditor',
  components: {
    ManageGroup,
    ManageMenu
  },
  data() {
    return{
      groupsData: null,
      menusData: null,
    }
  },
  methods:{
  }
}
</script>

<style scoped>
  .item-box{
    padding: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
  }

  .add-item-box{
    padding: 15px;
    border: 1px dashed #dfdfdf;
    border-radius: 5px;
  }

  .flip-list-move{
    transition: transform 0.5s;
  }

  .clickable{
    cursor: pointer;
  }

  .dragable{
    cursor: grab;
  }

  .dragable:active{
    cursor: grabbing;
  }

  .save-reorder{
    justify-content: flex-end;
  }
</style>