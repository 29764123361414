<template>
    <div class="add">

      <section class="container">

<div class="columns">

        <div class="column mt-3">
<div class="card">

  <header class="card-header">
    <div class="card-header-title">
      <i class="fas fa-plus-circle mr-2"></i> Add Item
    </div>
  </header>

  <div class="card-content">
    <div class="content">

        <b-field label="Import data"
          :type="form.importURL.status"
          :message="form.importURL.message"
        >
          <b-input v-model="form.importURL.data" placeholder="Provide Url" expanded></b-input>
            <p class="control">
              <b-button icon-left="cloud-download-alt" class="button is-info" :loading="isLoadingData" @click="getData()">Get Data</b-button>
            </p>
        </b-field>


        <hr/>

        <b-field label="Link"
          :type="form.link.status"
          :message="form.link.message"
        >
          <b-input v-model="form.link.data" placeholder="Partner Link" expanded></b-input>
        </b-field>

<b-field grouped expanded>
        <b-field label="Product Title" expanded
          :type="form.name.status"
          :message="form.name.message"
        >
            <b-input v-model="form.name.data"></b-input>
        </b-field>

        <b-field label="Price"
          :type="form.price.status"
          :message="form.price.message"
        >
            <b-input v-model="form.price.data"></b-input>
        </b-field>
</b-field>

        <b-field label="Description" expanded
          :type="form.descr.status"
          :message="form.descr.message"
        >
            <b-input type="textarea" v-model="form.descr.data"></b-input>
        </b-field>

        
        <b-field label="Age Group:"
          :type="form.age.status"
          :message="form.age.message"
        >
            <b-taginput
                type="is-info"
                v-model="form.age.data"
                :data="ageTags"
                :open-on-focus="true"
                ellipsis
                icon="tag"
                placeholder="Add Age Groups"
                aria-close-label="Delete Age Groups">
            </b-taginput>
        </b-field>

        <b-field label="Categories:"
          :type="form.category.status"
          :message="form.category.message"
        >
            <b-taginput
                type="is-primary"
                v-model="form.category.data"
                :data="categoryTags"
                :open-on-focus="true"
                ellipsis
                icon="tag"
                placeholder="Add Categoryes"
                aria-close-label="Delete Category">
            </b-taginput>
        </b-field>

        <b-field label="Relationship:"
          :type="form.relation.status"
          :message="form.relation.message"
        >
            <b-taginput
                type="is-danger"
                v-model="form.relation.data"
                :data="relationTags"
                :open-on-focus="true"
                ellipsis
                icon="tag"
                placeholder="Add Relations"
                aria-close-label="Delete Relation">
            </b-taginput>
        </b-field>

        <b-field label="Tags:"
          :type="form.tags.status"
          :message="form.tags.message"
        >
            <b-taginput
                type="is-ddmodular"
                v-model="form.tags.data"
                ellipsis
                icon="tag"
                placeholder="Add a tag"
                aria-close-label="Delete this tag">
            </b-taginput>
        </b-field>

        <b-field label="Tags Populator:"
        >
            <b-input v-model="tempTags" expanded></b-input>
            <p class="control">
              <b-button icon-left="angle-double-up" class="button is-info" @click="addToTags()">Add to Tags</b-button>
            </p>
        </b-field>
    </div>
  </div>



  <footer class="card-footer">
    <b-button @click="saveItem()" icon-left="save" type="is-primary">Add Item</b-button>
  </footer>

          </div>
        </div>


        <div class="column is-one-quarter mt-3">
<div class="card">

  <header class="card-header card-header-image">
    <div class="card-header-title">
      <i class="fas fa-image mr-2"></i> Item Image
    </div>
  </header>

  <div class="card-content">
    <div class="content">

                  <div v-if="form.image.data">
                    <p><strong>Image</strong></p>
                    <div class="mimagediv">
                      <div class="imageitem">
                        <span class="tag is-danger image-tag">
                          Delete
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteImage()"
                          ></button>
                        </span>
                        <img v-if="!this.form.image.drop"
                          class="mimage"
                          :src="form.image.data"
                        />
                        <img v-if="this.form.image.drop" class="mimage" :src="showFile(this.form.image.data)" />
                      </div>
                    </div>
                  </div>

                  <div v-if="!form.image.data">
                    <b-field label="Upload Image">
                      <b-upload
                        v-model="form.image.data"
                        drag-drop
                        accept="image/jpeg"
                        @input="dropedImage"
                      >
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon icon="upload" size="is-large"> </b-icon>
                            </p>
                            <p>Drop your files here or click to upload</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </div>             


    </div>
  </div>





          </div>

           <ItemAddSample v-if="inData" class="mt-3" :indata=form />
        </div>

       


</div>

      </section>
    </div>
</template>
<script>
import toast from "@/mixins/toast";
import ItemAddSample from '../components/ItemAddSample.vue';
export default {
  name: 'add',
  components: {
    ItemAddSample
  },
  mixins: [toast],
  data() {
    return{
      isLoadingData: false,
      inData: null,
      imageUpload: null,
      showUploaded: false,
      tempTags: null,
      form:{
        importURL:{
          data: null,
          message: null,
          status: null
        },
        link:{
          data: null,
          message: null,
          status: null
        },
        name:{
          data: null,
          message: null,
          status: null
        },
        price:{
          data: null,
          message: null,
          status: null
        },
        descr:{
          data: null,
          message: null,
          status: null
        },
        age:{
          data: [],
          message: null,
          status: null
        },
        category:{
          data: [],
          message: null,
          status: null
        },
        relation:{
          data: [],
          message: null,
          status: null
        },
        tags:{
          data: [],
          message: null,
          status: null
        },
        image:{
          drop: false,
          name: null,
          data: null,
          message: null,
          status: null
        }
      },
      ageTags:["baby","kid","teenager","adult","middleaged","elderly"],
      categoryTags:["decorative","apparel","romantic","funny","practical","floral","edible","portable","cosmetic","technology","personalized","vintage","readable","cuddly","toys","sporty","musical","geeky","unusual","classy","religious","alcohol"],
      relationTags:["acquaintance","friends","family","dating","couple","married","professional"],
    }
  },
  methods:{
    getAgeTags(text) {
      this.filteredAgeTags = this.ageTags.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    addToTags(){
      if(this.tempTags){
        let tags = this.tempTags.toLowerCase().split(",");
        this.form.tags.data = this.form.tags.data.concat(tags);
      }
    },
    deleteImage() {
      this.form.image.data = null;
      this.form.image.drop = false;
    },
    dropedImage(){
      this.form.image.drop = true;
    },
    showFile(index) {
      return URL.createObjectURL(index);
    },
    imageCheck() {
      if (this.imageMain == null) {
        this.toast("Please upload a Main Image for your listing", "is-danger");
        this.hasImageError = " And dont't forget to upload an image.";
        return false;
      } else {
        this.hasImageError = "";
        return true;
      }
    },
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    urlCheck(e) {
      //let re = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      if (!pattern.test(e)) {
        this.form.importURL.status = "is-danger";
        this.form.importURL.message = "Please enter a valid URL";
        return false;
      } else {
        this.form.importURL.message = null;
        this.form.importURL.status = "is-success";
        return true;
      }
    },
    getData(){
      if (this.urlCheck(this.form.importURL.data)){       
        this.isLoadingData = true;
        //begin post --------------------------------------------
          this.axios
            .post(`${this.$env.api}/getdata.php`, {
              dataURL: this.form.importURL.data,
              action: "data"
            })
            .then(response => {
              this.isLoadingData = false;
              console.log(response);
              if (response.data.success && response.data.data[0]!=="T") { // response.data.data[0]!=="T" if no python errors
                this.form.importURL.message = "Got data!";
                this.form.importURL.status = "is-success";
                this.inData = response.data.data;
                console.log(this.inData);
                this.form.name.data = this.inData.productTitle;
                this.form.price.data = this.inData.productPrice;
                this.form.descr.data = "";
                for(let i=0; i<this.inData.productDescr.length; i++){
                  this.form.descr.data += " "+this.inData.productDescr[i];
                }
                if(this.inData.productImage!==""){
                  this.form.image.data = this.inData.productImage;
                }else{
                  this.form.image.data = this.inData.productImageSM;
                }
                this.form.image.drop = false;
                
              }else{
                this.form.importURL.message = "Could not get data!";
                this.form.importURL.status = "is-danger";
              }
            })
            .catch(error => {
              this.isLoadingData = false;
              this.form.importURL.message = "Could not get data!";
              this.form.importURL.status = "is-danger";
              console.log(error);
            });
        //end post-------------------------------------------------
      }
    },
    uploadImageURL() {
      console.log("uploading image from URL");
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/upload_url_image.php`, {
          imageURL: this.form.image.data,
          token: localStorage.gshopToken,
          action: "upload"
        })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.form.image.data = null;
            this.form.image.name = response.data.filename;
            this.toast(response.data.message, "is-success");
            //
          } else {
            this.form.result = true;
            this.form.message = response.data.message;
            this.form.status = "is-danger";
            console.log(response.data);
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          this.form.result = true;
          this.form.message = "Sorry, something went wrong, please try again.";
        })
        .finally(() => {
          this.saveItemData();
        });
    },
    uploadImage() {
      console.log("uploading image");
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.form.image.data);
      formData.append("token", localStorage.gshopToken);
      formData.append("action", "main");
      //begin post --------------------------------------------
      this.axios({
        headers: {
          "Content-Type": "multipart/form-data"
        },
        method: "post",
        url: `${this.$env.api}/upload_image.php`,
        data: formData
      })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.form.image.data = null;
            this.form.image.name = response.data.filename;
            this.toast(response.data.message, "is-success");
            //
          } else {
            this.form.result = true;
            this.form.message = response.data.message;
            this.form.status = "is-danger";
            console.log(response.data);
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          this.form.result = true;
          this.form.message = "Sorry, something went wrong, please try again.";
        })
        .finally(() => {
          this.saveItemData();
        });
    },
    saveItem(){
      if (
        this.formCheck("link") &
        this.formCheck("name") &
        this.formCheck("price") &
        this.formCheck("descr") &
        this.formCheck("age") &
        this.formCheck("category") &
        this.formCheck("relation")
      ) {
        console.log("saving item");
        if(this.form.image.drop){
          this.uploadImage();
        }else{
          this.uploadImageURL();
        }
      }
    },
    saveItemData(){
          //begin post --------------------------------------------
          this.axios
            .post(`${this.$env.api}/manage_items.php`, {
              token: localStorage.gshopToken,
              action: "addItem",
              url: this.form.link.data,
              name: this.form.name.data,
              descr: this.form.descr.data,
              price: this.form.price.data,
              age: this.form.age.data.join(","),
              category: this.form.category.data.join(","),
              relation: this.form.relation.data.join(","),
              tags: this.form.tags.data.join(","),
              image: this.form.image.name
            })
            .then(response => {
              this.isLoading = false;
              console.log(response.data);
              if (response.data.success) {
                this.resetForm();
                this.toast(response.data.message, "is-success");
              } else {
                this.form.result = true;
                this.form.message = response.data.message;
                this.form.status = "is-danger";
                console.log(response.data);
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              this.form.result = true;
              this.form.message = "Sorry, something went wrong, please try again.";
            });
            // .finally(() => {
            //   this.saveItem();
            // });
    },
    resetForm(){
      this.tempTags = null;
      this.inData = null;
      this.imageUpload = null;
      this.showUploaded = false;
      this.form.importURL.data = null;
      this.form.importURL.message = null;
      this.form.importURL.status = null;
      this.form.link.data = null;
      this.form.link.message = null;
      this.form.link.status = null;
      this.form.name.data = null;
      this.form.name.message = null;
      this.form.name.status = null;
      this.form.price.data = null;
      this.form.price.message = null;
      this.form.price.status = null;
      this.form.descr.data = null;
      this.form.descr.message = null;
      this.form.descr.status = null;
      this.form.age.data = [];
      this.form.age.message = null;
      this.form.age.status = null;
      this.form.category.data = [];
      this.form.category.message = null;
      this.form.category.status = null;
      this.form.relation.data = [];
      this.form.relation.message = null;
      this.form.relation.status = null;
      this.form.tags.data = [];
      this.form.tags.message = null;
      this.form.tags.status = null;
      this.form.image.data = null;
      this.form.image.drop = false;
      this.form.image.name =null;
      this.form.image.message = null;
      this.form.image.status = null;     
    }
  },
  mounted(){
    console.log("Add Mounted");
  }

}
</script>




<style>

.mimage {
  width: 250px;
}
.mimagediv {
  display: inline-block;
}
.image {
  width: 250px;
  float: right;
}
.image-tag {
  position: absolute;
  top: 20px;
  right: 5px;
  z-index: 99;
}
.imageitem {
  position: relative;
  padding: 10px;
}
</style>
