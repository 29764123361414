import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/menu-editor',
    name: 'MenuEditor',
    component: () => import(/* webpackChunkName: "menu" */ '../views/MenuEditor.vue')
  },
  {
    path: '/items',
    name: 'Items',
    component: () => import(/* webpackChunkName: "items" */ '../views/Items.vue')
  },
  {
    path: '/add',
    name: 'Add',
    component: () => import(/* webpackChunkName: "add" */ '../views/Add.vue')
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next)=>{
  if ( to.name !== 'Login' && !store.state.user.isLogged ){
    next({
      path: '/',
      replace: true
    })
  } else {
    next();
  }
})

export default router
