import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    editing: 1,
    selectedGroup: null,
    user:{
      isLogged: false,
      token: null,
      name: null,
      email: null,
      status: null
    },
    modals:{
      editLink: false
    },
    editLinkData: null
  },
  mutations: {
    loginUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = payload.jwt;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      //local
      localStorage.gshopToken = payload.jwt;
      localStorage.gshopEmail = payload.email;
      localStorage.gshopFavs = payload.favs;
    },
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      //local
      localStorage.removeItem("gshopToken");
      localStorage.removeItem("gshopEmail");
      localStorage.removeItem("gshopFavs");
    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = localStorage.gshopToken;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
    },
    statusUser(state, status){
      state.status = status; 
    },
    setEdit(state, payload) {
      state.editing = payload;
    },
    setEditLink(state, payload) {
      state.editLinkData = payload;
    },
    unsetEdit(state) {
      state.editing = null;
    },
    showModal(state, payload) {
      console.log("showModal: "+payload);
      state.modals[payload] = true;
    },
    hideModal(state, payload) {
      state.modals[payload] = false;
    },
    toggleModal(state, payload) {
      state.modals[payload] = !state[payload];
    },
    setGroup(state, payload) {
      state.selectedGroup = payload;
    },
    unsetGroup(state) {
      state.selectedGroup = null;
    },
  },
  actions: {
  },
  modules: {
  }
})
