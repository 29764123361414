<template>
  <div>
    <div class="item-box" v-if="!groupID">
      Select a Menu Group
    </div>


    <div v-if="groupID">

              <div class="add-item-box my-5">
                  <b-field label="Item Name"
                      :type="form.name.status"
                      :message="form.name.message"
                  >
                      <b-input v-model="form.name.data" placeholder="Menu Item Name" type="text" expanded></b-input>
                  </b-field>

                    <b-field label="Age Group"
                      :type="form.age.status"
                      :message="form.age.message"
                    >
                        <b-taginput
                            type="is-info"
                            v-model="form.age.data"
                            :data="ageTags"
                            :open-on-focus="true"
                            ellipsis
                            icon="tag"
                            placeholder="Add Age Groups"
                            aria-close-label="Delete Age Groups">
                        </b-taginput>
                    </b-field>

                    <b-field label="Categories"
                      :type="form.category.status"
                      :message="form.category.message"
                    >
                        <b-taginput
                            type="is-primary"
                            v-model="form.category.data"
                            :data="categoryTags"
                            :open-on-focus="true"
                            ellipsis
                            icon="tag"
                            placeholder="Add Categoryes"
                            aria-close-label="Delete Category">
                        </b-taginput>
                    </b-field>

                    <b-field label="Relationship"
                      :type="form.relation.status"
                      :message="form.relation.message"
                    >
                        <b-taginput
                            type="is-danger"
                            v-model="form.relation.data"
                            :data="relationTags"
                            :open-on-focus="true"
                            ellipsis
                            icon="tag"
                            placeholder="Add Relations"
                            aria-close-label="Delete Relation">
                        </b-taginput>
                    </b-field>

                <div v-if="!editID" class="has-text-centered"> 
                  <b-button @click="addItem()" type="is-primary" icon-left="plus-circle"> Add Item</b-button> 
                </div>

                <div v-if="editID" class="buttons save-reorder"> 
                  <b-button @click="resetForm()" icon-left="times-circle"> Cancel </b-button>
                  <b-button @click="updateItem()" type="is-info" icon-left="save"> Save Item</b-button> 
                </div>

              </div>


                <div class="item-box" v-if="!hasMenu">
                  No Menu Items
                </div>  
                
                <draggable v-model="menuData" v-bind="dragOptions" :group="'menue'+groupID" @start="drag=true" @end="drag=false" :move="checkMove" handle=".handle">
                   <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                  <div v-for="item in menuData" :key="item.id">
                    <div class="item-box level mb-5">
                      <div class="level-left">
                        <span class="has-text-weight-bold dragable handle">{{item.name}}</span>
                      </div>
                      <div class="level-right">
                        <div @click="editItem(item.id)" class="edit-bt mr-2">
                          <b-icon icon="edit" size="is-medium"></b-icon>
                        </div>
                        <div @click="warningDelete(item.id)" class="delete-bt mr-2">
                          <b-icon icon="trash" size="is-medium" class="clickable"></b-icon>
                        </div>
                        <div class="handle">
                          <b-icon icon="grip-lines" size="is-medium" type="is-info" class="dragable"></b-icon>
                        </div>
                      </div>                   
                    </div>
                  </div>
                   </transition-group>
                </draggable>

                <div v-if="showSavePos" class="my-5 buttons save-reorder">
                    <b-button @click="resetOrder()" icon-left="redo"> Reset Order</b-button> 
                    <b-button @click="saveOrder()" type="is-info" icon-left="save" :loading="isSavingPos"> Save new Order</b-button>           
                </div>


           
        </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import toast from "@/mixins/toast";
export default {
  name: "manageMenu",
  components: {
    draggable,
  },
  mixins: [toast],
  data() {
    return {
      drag:false,
      menuData: null,
      hasMenu: false,
      showSavePos: false,
      editID: null,
      isSaving: false,
      isSavingPos: false,
      form: {
        name: {
          data: null,
          message: null,
          status: null,
        },
        age: {
          data: [],
          message: null,
          status: null,
        },
        category: {
          data: [],
          message: null,
          status: null,
        },
        relation: {
          data: [],
          message: null,
          status: null,
        },
      },
      ageTags: ["baby", "kid", "teenager", "adult", "middleaged", "elderly"],
      categoryTags: [
        "decorative",
        "apparel",
        "romantic",
        "funny",
        "practical",
        "floral",
        "edible",
        "portable",
        "cosmetic",
        "technology",
        "personalized",
        "vintage",
        "readable",
        "cuddly",
        "toys",
        "sporty",
        "musical",
        "geeky",
        "unusual",
        "classy",
        "religious",
        "alcohol",
      ],
      relationTags: [
        "acquaintance",
        "friends",
        "family",
        "dating",
        "couple",
        "married",
        "professional",
      ],
    };
  },
  methods: {
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    loadMenu() {
      this.menuData = null;
      this.axios
        .post(`${this.$env.api}/manage_menus.php`, {
          token: localStorage.gshopToken,
          action: "getList",
          groupID: this.groupID,
        })
        .then((response) => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.hasMenu = true;
            this.menuData = response.data.items;
          } else {
            this.hasMenu = false;
            console.log("Error getting data");
          }
        })
        .catch((error) => {
          this.hasMenu = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    checkMove() {
      console.log("did move group");
      this.showSavePos = true;
      console.log(this.menuData);
    },
    editItem(id) {
      console.log("Edit id:" + id);
      this.editID = id;
      let editData = this.menuData.find((item) => item.id === id);
      this.form.name.data = editData.name;
      this.form.age.data = editData.age.split(",");
      this.form.category.data = editData.category.split(",");
      this.form.relation.data = editData.relation.split(",");
    },
    addItem() {
      if (this.formCheck("name")) {
        this.axios
          .post(`${this.$env.api}/manage_menus.php`, {
            token: localStorage.gshopToken,
            action: "addItem",
            name: this.form.name.data,
            age: this.form.age.data.join(","),
            category: this.form.category.data.join(","),
            relation: this.form.relation.data.join(","),
            groupID: this.groupID,
          })
          .then((response) => {
            console.log(response.data);
            this.isLoading = false;
            if (response.data.success) {
              this.toast(response.data.message, "is-success");
              this.loadMenu();
              this.resetForm();
            } else {
              this.toast(response.data.message, "is-danger");
            }
          })
          .catch((error) => {
            this.hasMenu = false;
            console.log(error);
            this.toast(error, "is-danger");
            //this.errored = true;
          });
        //.finally(() => (this.loading = false));
      }
    },
    warningDelete(id) {
      this.$buefy.dialog.confirm({
        title: "Deleting Menu Group",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteItem(id),
      });
    },
    deleteItem(id) {
      this.axios
        .post(`${this.$env.api}/manage_menus.php`, {
          token: localStorage.gshopToken,
          action: "deleteItem",
          itemID: id,
        })
        .then((response) => {
          if (response.data.success) {
            this.loadMenu();
            this.toast(response.data.message, "is-success");
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch((error) => {
          console.log(error);
          this.toast(error, "is-danger");
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    resetForm() {
      this.form.name.data = null;
      this.form.name.message = null;
      this.form.name.status = null;
      this.form.age.data = [];
      this.form.age.message = null;
      this.form.age.status = null;
      this.form.category.data = [];
      this.form.category.message = null;
      this.form.category.status = null;
      this.form.relation.data = [];
      this.form.relation.message = null;
      this.form.relation.status = null;
      this.editID = null;
    },
    resetOrder() {
      this.menuData = this.menuData.sort((a, b) => a.pos - b.pos);
      this.showSavePos = false;
    },
    saveOrder() {
      for (let i = 0; i < this.menuData.length; i++) {
        this.menuData[i].pos = i + 1;
        this.updatePos(this.menuData[i].id, this.menuData[i].pos);
      }
      this.showSavePos = false;
    },
    updatePos(id, pos) {
      this.isSavingPos = true;
      this.axios
        .post(`${this.$env.api}/manage_menus.php`, {
          token: localStorage.gshopToken,
          action: "updatePos",
          itemID: id,
          pos: pos,
        })
        .then((response) => {
          if (response) {
            this.isSavingPos = false;
          }
        })
        .catch((error) => {
          this.isSavingPos = false;
          this.toast(error, "is-danger");
        });
    },
    updateItem() {
      if (this.formCheck("name")) {
        this.axios
          .post(`${this.$env.api}/manage_menus.php`, {
            token: localStorage.gshopToken,
            action: "updateItem",
            itemID: this.editID,
            name: this.form.name.data,
            age: this.form.age.data.join(","),
            category: this.form.category.data.join(","),
            relation: this.form.relation.data.join(","),
          })
          .then((response) => {
            this.isSaving = false;
            if (response.data.success) {
              this.toast(response.data.message, "is-success");
              this.resetForm();
              this.loadMenu();
            } else {
              this.toast(response.data.message, "is-danger");
            }
          })
          .catch((error) => {
            this.isSaving = false;
            this.toast(error, "is-danger");
          });
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    groupID() {
      return this.$store.state.selectedGroup;
    },
  },
  watch: {
    groupID: function () {
      this.loadMenu();
    },
  },
};
</script>

<style scoped>
.item-box {
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

.add-item-box {
  padding: 15px;
  border: 1px dashed #dfdfdf;
  border-radius: 5px;
  background: #f7f7f7;
}

.flip-list-move {
  transition: transform 0.5s;
}

.clickable {
  cursor: pointer;
}

.dragable {
  cursor: grab;
}

.dragable:active {
  cursor: grabbing;
}

.save-reorder {
  justify-content: flex-end;
}
</style>