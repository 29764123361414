import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";

Vue.config.productionTip = false;

Vue.prototype.$env = {
  siteTitle:"Giftomany.com",
  siteUrl:"https://giftomany.com",
  shareImg:"/img/share.jpg",
  api: "https://giftomany.com/api",
  imagesURL: "https://giftomany.com/productimages/"
}

Vue.use(Buefy, {
  defaultIconPack: "fas"
});
Vue.use(VueAxios, axios);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
