<template>
    <b-navbar class="shadow" wrapper-class="container" :mobile-burger="false" :fixed-top="true" type="is-dark">
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
                <strong>Giftomany</strong>&nbsp;Admin
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item @click="logout()">
                Logout
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
                Dashboard
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/menu-editor' }">
                Menu
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/items' }">
                Items
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/add' }">
                Add
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<style scoped>
    .shadow{
        box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.17);
    }
    .st0{fill:#5C962A;}
    .st1{fill:#7CBC4B;}
    .st2{fill:#FF0000;}
    .st3{fill:#AF0202;}
    .st4{fill:#507C25;}
</style>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
        menu:[        
            {
                name:"Birthday Gifts",
                link:"/",
                list:["1st Birthday","10st Birthday","16th Birthday","18th Birthday","20th Birthday","21st Birthday","25th Birthday","25th Birthday","30th Birthday","40th Birthday","50th Birthday","60th Birthday","70th Birthday","80th Birthday"]
            },
            {
                name:"by Person",
                link:"/",
                list:["for Business Partners","for Customers","for Employees","for Your Boss","for Your Boss","Friend","Husband","Brother","Girlfriend","Wife","Sister","Grandma","Grandpa","Father","Mother","Boys","Girls","Newborns"]
            },
            {
                name:"by Ocasion",
                link:"/",
                list:["Anniversaries","Christmas","Advent Calendars","Secret Santa","Easter","Father's Day","Halloween","New Parents","for New Mothers","for Pregnant Women","Farewells","for Retirement","1st Year of School","High School Graduation","Mother's Day","Valentine's Day","Wedding Anniversaries"]
            },
        ],     
    }
  },
  methods:{
    toggleSidebar(){
      this.$store.commit("toggleSidebar");
    },
    logout(){
        this.$store.commit("logoutUser");
        this.$router.push({ path: "/" }).catch(err => {console.log(err)});
    }
  }
}
</script>

