<template>
  <div class="dashboard">
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
    <section class="container mt-3">
    <div class="columns">
      <div class="column is-one-quarter">

        <div class="card">
          <header class="card-header card-header-actions">
            <div class="card-header-title">
              <i class="fas fa-asterisk mr-2"></i> Actions
            </div>
          </header>

          <div class="card-content">
              <b-menu>
                <b-menu-list>
                  <b-menu-item tag="router-link" to="/items" label="Manage Items" icon="list"></b-menu-item>
                  <b-menu-item tag="router-link" to="/add" label="Add Item" icon="plus-circle"></b-menu-item>
                  <b-menu-item tag="router-link" to="/menu-editor" label="Menu Editor" icon="clipboard-list"></b-menu-item>
                </b-menu-list>
              </b-menu>
          </div>
        </div><!-- endcard -->

      </div>
      <div class="column">

                <div class="card">
          <header class="card-header card-header-stats">
            <div class="card-header-title">
              <i class="fas fa-chart-line mr-2"></i> Stats
            </div>
          </header>

          <div class="card-content">
            <div class="content">

                <div class="columns">
                  <div class="column">
                   <TotalItems :indata="totals"/>
                  </div>
                  <div class="column">
                    <TotalClicks :indata="totals"/>
                  </div>
                </div>

              <hr/> 

                <div class="columns">
                  <div class="column">
                    Daily Stats:
                  </div>
                  <div class="column">
                    Popular Items:
                  </div>
                </div>

            </div>
          </div>
        </div><!-- endcard -->

      </div>
    </div>

    </section>
  </div>
</template>

<script>

import TotalItems from '@/components/dashboard/TotalItems.vue';
import TotalClicks from '@/components/dashboard/TotalClicks.vue';
export default {
  name: 'dashboard',
  components: {
    TotalItems,
    TotalClicks
  },
  data() {
    return {
      isLoading: false,
      totals: {
        all: "-",
        active: "-",
        clicks: "-"
      }
    };
  },
    methods: {
    loadTotals() {
      this.isLoading = true;
      this.axios
        .post(`${this.$env.api}/dashboard.php`, {
          token: localStorage.gshopToken,
          action: "getTotals"
        })
        .then(response => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.totals.all = response.data.all;
            this.totals.active = response.data.active;
            this.totals.clicks = response.data.clicks;
          } else {
            this.totalItems = "no data";
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.loadTotals();
  }

}
</script>
