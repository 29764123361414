<template>
  <div>
    <footer class="footer">
      <div class="container has-text-centered">
        {{ currentYear }} &copy; Syntho.net | All Rights Reserved.
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  computed: {
    ...mapState(["isLoged"])
  }
};
</script>
