<template>
  <div class="box">
    <div class="level">
      <div class="level-left is-size-4">
        Total Clicks:
      </div>
      <div class="level-right">         
          <div class="is-size-4">
            {{indata.clicks}}
          </div>             
      </div>
    </div>
  
  </div>
</template>
<script>

export default {
  name: "TotalClicks",
  props:{
    indata: Object
  }
};
</script>

