<template>
    <div class="add">

      <section class="container">

<div class="columns">



        <div class="column mt-3">
<div class="card">

  <header class="card-header">
    <div class="card-header-title">
      <i class="fas fa-edit mr-2"></i> Edit Item
    </div>
  </header>

  <div class="card-content">
    <div class="content">



        <b-field label="Item Link"
          :type="form.link.status"
          :message="form.link.message"
        >
          <b-input v-model="form.link.data" placeholder="Item Link" expanded></b-input>
        </b-field>

<b-field grouped expanded>
        <b-field label="Product Title" expanded
          :type="form.name.status"
          :message="form.name.message"
        >
            <b-input v-model="form.name.data"></b-input>
        </b-field>

        <b-field label="Price"
          :type="form.price.status"
          :message="form.price.message"
        >
            <b-input v-model="form.price.data"></b-input>
        </b-field>
</b-field>

        <b-field label="Description" expanded
          :type="form.descr.status"
          :message="form.descr.message"
        >
            <b-input type="textarea" v-model="form.descr.data"></b-input>
        </b-field>

        
        <b-field label="Age Group:"
          :type="form.age.status"
          :message="form.age.message"
        >
            <b-taginput
                type="is-info"
                v-model="form.age.data"
                :data="ageTags"
                :open-on-focus="true"
                ellipsis
                icon="tag"
                placeholder="Add Age Groups"
                aria-close-label="Delete Age Groups">
            </b-taginput>
        </b-field>

        <b-field label="Categories:"
          :type="form.category.status"
          :message="form.category.message"
        >
            <b-taginput
                type="is-primary"
                v-model="form.category.data"
                :data="categoryTags"
                :open-on-focus="true"
                ellipsis
                icon="tag"
                placeholder="Add Categoryes"
                aria-close-label="Delete Category">
            </b-taginput>
        </b-field>

        <b-field label="Relationship:"
          :type="form.relation.status"
          :message="form.relation.message"
        >
            <b-taginput
                type="is-danger"
                v-model="form.relation.data"
                :data="relationTags"
                :open-on-focus="true"
                ellipsis
                icon="tag"
                placeholder="Add Relations"
                aria-close-label="Delete Relation">
            </b-taginput>
        </b-field>

        <b-field label="Tags:"
          :type="form.tags.status"
          :message="form.tags.message"
        >
            <b-taginput
                type="is-ddmodular"
                v-model="form.tags.data"
                ellipsis
                icon="tag"
                placeholder="Add a tag"
                aria-close-label="Delete this tag">
            </b-taginput>
        </b-field> 


        <b-field label="Tags Populator:"
        >
            <b-input v-model="tempTags" expanded></b-input>
            <p class="control">
              <b-button icon-left="angle-double-up" class="button is-info" @click="addToTags()">Add to Tags</b-button>
            </p>
        </b-field>


    </div>
  </div>

  <footer class="card-footer">
    <b-button @click="saveItem()" icon-left="save" type="is-primary">Save Item</b-button>
  </footer>



          </div>
        </div>




<div class="column is-one-quarter mt-3">
<div class="card">

  <header class="card-header card-header-image">
    <div class="card-header-title">
      <i class="fas fa-image mr-2"></i> Edit Image
    </div>
  </header>

  <div class="card-content">
    <div class="content">

                  <div v-if="form.image.name">
                    <p><strong>Image</strong></p>
                    <div class="mimagediv">
                      <div class="imageitem">
                        <span class="tag is-danger image-tag">
                          Delete
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteImage(form.image.name)"
                          ></button>
                        </span>
                        <img
                          class="mimage"
                          :src="$env.imagesURL+form.image.name"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="!form.image.name">
                    <b-field label="Upload Image">
                      <b-upload
                        v-model="form.image.data"
                        drag-drop
                        accept="image/jpeg"
                        @input="uploadImage"
                      >
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon icon="upload" size="is-large"> </b-icon>
                            </p>
                            <p>Drop your files here or click to upload</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </div>
    </div>
  </div>


          </div>

          <ItemEditSample class="mt-3" :indata=form />

       </div>


</div>
      </section>
    </div>
</template>
<script>
import toast from "@/mixins/toast";
import ItemEditSample from '../components/ItemEditSample.vue';
export default {
  name: 'edit',
  components: {
    ItemEditSample
  },
  mixins: [toast],
  data() {
    return{
      isLoadingData: false,
      itemData: null,
      imageUpload: null,
      showUploaded: false,
      tempTags: null,
      form:{
        link:{
          data: null,
          message: null,
          status: null
        },
        name:{
          data: null,
          message: null,
          status: null
        },
        price:{
          data: null,
          message: null,
          status: null
        },
        descr:{
          data: null,
          message: null,
          status: null
        },
        age:{
          data: [],
          message: null,
          status: null
        },
        category:{
          data: [],
          message: null,
          status: null
        },
        relation:{
          data: [],
          message: null,
          status: null
        },
        tags:{
          data: [],
          message: null,
          status: null
        },
        image:{
          name: null,
          data: null,
          message: null,
          status: null
        }
      },
      ageTags:["baby","kid","teenager","adult","middleaged","elderly"],
      categoryTags:["decorative","apparel","romantic","funny","practical","floral","edible","portable","cosmetic","technology","personalized","vintage","readable","cuddly","toys","sporty","musical","geeky","unusual","classy","religious","alcohol"],
      relationTags:["acquaintance","friends","family","dating","couple","married","professional"],
    }
  },
  methods:{
    getAgeTags(text) {
      this.filteredAgeTags = this.ageTags.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    addToTags(){
      if(this.tempTags){
        let tags = this.tempTags.toLowerCase().split(",");
        this.form.tags.data = this.form.tags.data.concat(tags);
      }
    },
    deleteImage(image) {
      console.log("deleteing!" + image);
      this.isLoading = true;
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/delete_image.php`, {
          token: localStorage.gshopToken,
          filename: image
        })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-danger");
              this.form.image.name = "";
              this.saveImage();
          } else {
            this.images.result = true;
            this.images.message = response.data.message;
            this.images.status = "is-danger";
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.images.result = true;
          this.images.message = "Sorry, something went wrong, please try again.";
          console.log(error);
        });
    },
    imageCheck() {
      if (this.imageMain == null) {
        this.toast("Please upload a Main Image for your listing", "is-danger");
        this.hasImageError = " And dont't forget to upload an image.";
        return false;
      } else {
        this.hasImageError = "";
        return true;
      }
    },
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    urlCheck(e) {
      //let re = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      if (!pattern.test(e)) {
        this.form.link.status = "is-danger";
        this.form.link.message = "Please enter a valid URL";
        return false;
      } else {
        this.form.link.message = null;
        this.form.link.status = "is-success";
        return true;
      }
    },
    loadItemData() {
      console.log("loading item data...");
      this.isLoading = true;
      this.axios
        .post(`${this.$env.api}/manage_items.php`, {
          token: localStorage.gshopToken,
          itemID: this.$store.state.editing,
          action: "getItem"
        })
        .then(response => {
          //console.log(response.data);
          this.isLoading = false;
          console.log("loaded!");
          if (response.data.success) {
            this.itemData = response.data.item;
            console.log("item data:");
            console.log(response.data.item);
            this.setFormData();
          } else {
            this.hasItems = false;
          }
        })
        .catch(error => {
          this.hasItems = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    setFormData() {
      console.log("setting form data..");
      this.form.link.data = this.itemData.url;
      this.form.name.data = this.itemData.name;
      this.form.price.data = this.itemData.price;
      this.form.age.data = this.itemData.age.split(",");
      this.form.category.data = this.itemData.category.split(",");
      this.form.relation.data = this.itemData.relation.split(",");
      this.form.tags.data = this.itemData.tags.split(",");   
      this.form.descr.data = this.itemData.descr;
      this.form.image.data = this.itemData.image;
      this.form.image.name = this.itemData.image; 
    },
    uploadImage() {
        console.log("uploading image");
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.form.image.data);
        formData.append("token", localStorage.gshopToken);
        formData.append("action", "main");
        //begin post --------------------------------------------
        this.axios({
          headers: {
            "Content-Type": "multipart/form-data"
          },
          method: "post",
          url: `${this.$env.api}/upload_image.php`,
          data: formData
        })
          .then(response => {
            this.isLoading = false;
            console.log(response.data);
            if (response.data.success) {
              this.form.image.name = response.data.filename;
              this.toast(response.data.message, "is-success");
              this.saveImage();

            } else {
              this.form.result = true;
              this.form.message = response.data.message;
              this.form.status = "is-danger";
              console.log(response.data);
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            this.form.result = true;
            this.form.message = "Sorry, something went wrong, please try again.";
          });
          // .finally(() => {
          //   this.saveImage();
          // });        
    },
    saveImage(){
      //begin post --------------------------------------------
          this.axios
            .post(`${this.$env.api}/manage_items.php`, {
              token: localStorage.gshopToken,
              action: "updateImage",
              itemID: this.$store.state.editing,          
              image: this.form.image.name
            })
            .then(response => {
              this.isLoading = false;
              console.log(response.data);
              if (response.data.success) {                
                this.toast(response.data.message, "is-success");
              } else {
                this.form.result = true;
                this.form.message = response.data.message;
                this.form.status = "is-danger";
                console.log(response.data);
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              this.form.result = true;
              this.form.message = "Sorry, something went wrong, please try again.";
            });
    },
    saveItem(){
      if (
        this.formCheck("link") &
        this.formCheck("name") &
        this.formCheck("price") &
        this.formCheck("descr") &
        this.formCheck("age") &
        this.formCheck("category") &
        this.formCheck("relation")
      ) {
        console.log("saving item");
        this.saveItemData();
      }
    },
    saveItemData(){
          //begin post --------------------------------------------
          this.axios
            .post(`${this.$env.api}/manage_items.php`, {
              token: localStorage.gshopToken,
              action: "updateItem",
              itemID: this.$store.state.editing,
              url: this.form.link.data,
              name: this.form.name.data,
              descr: this.form.descr.data,
              price: this.form.price.data,
              age: this.form.age.data.join(","),
              category: this.form.category.data.join(","),
              relation: this.form.relation.data.join(","),
              tags: this.form.tags.data.join(",")
            })
            .then(response => {
              this.isLoading = false;
              console.log(response.data);
              if (response.data.success) {
                //this.resetForm();
                this.toast(response.data.message, "is-success");
              } else {
                this.form.result = true;
                this.form.message = response.data.message;
                this.form.status = "is-danger";
                this.toast(response.data.message, "is-danger");
                console.log(response.data);
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              this.form.result = true;
              this.form.message = "Sorry, something went wrong, please try again.";
            })
            .finally(() => {
              this.$store.commit("setEdit", null);
              this.$router.go(-1);
              //this.loadItemData();
            });
    },
    resetForm(){
      this.tempTags = null;
      this.itemData = null;
      this.imageUpload = null;
      this.showUploaded = false;
      this.form.link.data = null;
      this.form.link.message = null;
      this.form.link.status = null;
      this.form.name.data = null;
      this.form.name.message = null;
      this.form.name.status = null;
      this.form.price.data = null;
      this.form.price.message = null;
      this.form.price.status = null;
      this.form.descr.data = null;
      this.form.descr.message = null;
      this.form.descr.status = null;
      this.form.age.data = [];
      this.form.age.message = null;
      this.form.age.status = null;
      this.form.category.data = [];
      this.form.category.message = null;
      this.form.category.status = null;
      this.form.relation.data = [];
      this.form.relation.message = null;
      this.form.relation.status = null;
      this.form.tags.data = [];
      this.form.tags.message = null;
      this.form.tags.status = null;
      this.form.image.data = null;
      this.form.image.name = null;
      this.form.image.message = null;
      this.form.image.status = null;     
    }
  },
  mounted(){
    this.loadItemData();
    console.log("Edit Mounted");
  }

}
</script>




<style>

.mimage {
  width: 250px;
}
.mimagediv {
  display: inline-block;
}
.image {
  width: 250px;
  float: right;
}
.image-tag {
  position: absolute;
  top: 20px;
  right: 5px;
  z-index: 99;
}
.imageitem {
  position: relative;
  padding: 10px;
}
</style>
