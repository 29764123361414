<template>
  <section>
    <b-table 
      :data="isEmpty ? [] : listItems"
      ref="table"
      :loading="isLoading"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      :show-detail-icon="showDetailIcon"
    >

    
        <b-table-column field="id" label="ID" width="40" numeric sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Name" sortable searchable v-slot="props">
          <a @click="toggle(props.row)" v-html="props.row.name"></a>
        </b-table-column>

        <b-table-column field="price" label="Price" sortable searchable v-slot="props" width="80">
          {{props.row.price}}
        </b-table-column>
        <b-table-column custom-key="actions" label="Link" v-slot="props" width="60">
          <div @click="editLink(props.row.id,props.row.url)" class="link-bt"><b-icon icon="link" size="is-medium" :class="[detectURL(props.row.url)?'link-green':'link-red']"></b-icon></div>          
        </b-table-column>
        <b-table-column custom-key="actions" label="Edit" v-slot="props" width="60">
          <div @click="edit(props.row.id)" class="edit-bt"><b-icon class="bicon-bt" icon="edit" size="is-medium"></b-icon></div>          
        </b-table-column>
        <b-table-column custom-key="actions" label="Delete" v-slot="props" width="60">
          <div @click="warningDelete(props.row.id,props.row.image)" class="delete-bt"><b-icon class="bicon-bt" icon="trash" size="is-medium"></b-icon></div>
        </b-table-column>
        <b-table-column custom-key="visible" label="Visible" v-slot="props" width="100">
          <b-field class="mt-1">           
              <b-switch
                :ref="'status' + props.row.id"
                @input="
                  visibleChange(props.row.id);
                  props.row.status = props.row.status == 0 ? 1 : 0;
                "
                :value="Boolean(Number(props.row.status))"
              ></b-switch>        
          </b-field>
        </b-table-column>


      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-128x128">
              <img :src="$env.imagesURL + props.row.image" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="ddtitle is-size-5" v-html="props.row.name"></div>
              <div class="ddseparator my-4"></div>
              <p><strong>Description:</strong> <span v-html="props.row.descr"></span></p>
              <p><strong>Age Groups:</strong></p>
              <div class="tags" v-if="props.row.age">
                <span
                  class="tag is-info"
                  v-for="(age,index) in tags(props.row.age)"
                  :key="age+index"
                  v-html="age"></span>
              </div>
              <p><strong>Categories:</strong></p>
              <div class="tags" v-if="props.row.category">
                <span
                  class="tag is-success"
                  v-for="(category,index) in tags(props.row.category)"
                  :key="category+index"
                  v-html="category"
                ></span>
              </div>
              <p><strong>Relationship:</strong></p>
              <div class="tags" v-if="props.row.relation">
                <span
                  class="tag is-danger"
                  v-for="(relation,index) in tags(props.row.relation)"
                  :key="relation+index"
                  v-html="relation"
                ></span>
              </div>
              <p><strong>Tags:</strong></p>
              <div class="tags" v-if="props.row.tags">
                <span
                  class="tag is-ddmodular"
                  v-for="(tag,index) in tags(props.row.tags)"
                  :key="tag+index"
                  v-html="tag"
                ></span>
              </div>
            </div>
          </div>
        </article>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Items.</p>
          </div>
        </section>
      </template>
    </b-table>

<div class="mt-4">
    <b-pagination
      :total="totalItems"
      v-model="currentPage"
      :range-before="1"
      :range-after="3"
      :per-page="10"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      @change="loadData"
      >
    </b-pagination>
</div>
  </section>
</template>
<script>

import toast from "@/mixins/toast";

export default {
  name: "ManageList",
  mixins:[toast],
  data() {
    return {
      visibility: {},
      defaultOpenedDetails: [0],
      showDetailIcon: true,
      published: true,
      isEmpty: true,
      isLoading: true,
      listItems: [],
      totalItems: null,
      currentPage:1,

    };
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.axios
        .post(`${this.$env.api}/manage_items.php`, {
          token: localStorage.gshopToken,
          startID: (this.currentPage - 1) * 10,
          action: "getList"
        })
        .then(response => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.listItems = response.data.items;
            this.isEmpty = false;

            this.totalItems = response.data.count;
            console.log("Total Result:" + this.listItems.length);
          } else {
            this.hasItems = false;
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasItems = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    edit(id) {
      this.$store.commit("setEdit", id);
      this.$router.push("/edit");
      //this.$store.commit("SET_EDIT_ID", id);
      //this.$store.commit("SHOW_MODAL", { modal: "itemEditor", state: true });
    },
    editLink(id,url) {
      this.$store.commit("setEditLink", {"id":id,"url":url});
      this.$store.commit("showModal", "editLink");
      //this.$router.push("/edit");
      //this.$store.commit("SET_EDIT_ID", id);
      //this.$store.commit("SHOW_MODAL", { modal: "itemEditor", state: true });
    },
    detectURL(url){
      return url.includes("amzn");
    },
    deleteImage(id,image) {
      console.log("deleteing!" + image);
      this.isLoading = true;
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/delete_image.php`, {
          token: localStorage.gshopToken,
          filename: image
        })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-danger");
            this.delete(id);
          } else {
            this.images.result = true;
            this.images.message = response.data.message;
            this.images.status = "is-danger";
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.images.result = true;
          this.images.message = "Sorry, something went wrong, please try again.";
          console.log(error);
        });
    },
    delete(id) {
      console.log(id);
      this.axios
        .post(`${this.$env.api}/manage_items.php`, {
          token: localStorage.gshopToken,
          action: "deleteItem",
          itemID: id
        })
        .then(response => {
          //console.log(response.data);
          if (response.data.success) {
            console.log("Result:" + response.data.message);
            this.loadData();
          } else {
            console.log("Result:" + response.data.message);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    warningDelete(id,image) {
      this.$buefy.dialog.confirm({
        title: "Deleting Image",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteImage(id,image)
      });
    },
    tags(intags) {
      return intags.split(",");
    },
    visibleChange(id) {
      let i = this.$refs["status" + id].computedValue ? 1 : 0;
      console.log(id + " change to " + i);
      this.axios
        .post(`${this.$env.api}/manage_items.php`, {
          token: localStorage.gshopToken,
          action: "updateStatus",
          itemID: id,
          status: i
        })
        .then(response => {
          //console.log(response.data);
          if (response.data.success) {
            console.log("Result:" + response.data.message);
          } else {
            console.log("Result:" + response.data.message);
          }
        })
        .catch(error => {
          console.log(error);
        });
      //.finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.loadData();
    this.$root.$on("linkUpdated",()=>{
      this.loadData();
    });
  }
};
</script>

