<template>
  <div id="app">
    <Navbar v-if="['Login'].indexOf($route.name) == -1" />
    <router-view/>
    <Footer v-if="['Login'].indexOf($route.name) == -1" />
    <EditLink v-if="['Login'].indexOf($route.name) == -1" />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import EditLink from './components/EditLink.vue';
export default {
  name: 'app',
  components: {
    Navbar,
    Footer,
    EditLink
  },
  mounted() {
    console.log("APP MOUNTED!");
    console.log("Begin User Check!");
    if (!this.$store.state.user.isLogged) {
      this.checkLoged();
      console.log(this.$store.state.user.isLogged)
      //console.log(this.$route.name);
    }
  },
  methods:{    
    checkLoged: function() {
      if (localStorage.gshopToken) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.gshopToken);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/validate.php`, {
            token: localStorage.gshopToken
          })
          .then(response => {
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit('validateUser', response.data.data);
              console.log("Loged!");
              this.$router.push({ path: "/dashboard" }).catch(err => {console.log(err)});
              console.log(response.data.data);
            }
          })
          .catch(error => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    }
  }
}
</script>


<style lang="scss">
  //Import Fonts
//import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap');
  //font-family: 'Bebas Neue', cursive;
  //font-family: 'Roboto', sans-serif;

  // Import Fontawesome
  @import 'static/fontawesome/css/all.min.css';
  // Import Bulma's core
  @import "~bulma/sass/utilities/_all";
  // Set your colors
  $primary: #7CBC4B;
  $success: #74b570;
  // $modal-background-background-color: #000;
  $primary-invert: findColorInvert($primary);
  //theme mod
  $mydark: #23262b; //#00bb8f; //00D1B2
  $mylight: #ebebeb; //#00bb8f; //00D1B2
  // $dark: #0d4553;
  $filter: #F4F4F4; //#00bb8f; //00D1B2
  $sidebar-box-shadow: 0px;
  $sidebar-width: 330px;
  $body-background-color: #4a4f54;
  $content-heading-color: #000000;

  $ddportable: #ffcd0b;
  $ddmodular: #f37422;
  $ddcustom: #ed3a25;
  $ddgraphic: #71cee3;
  $ddproject: #b393b0;

  $ddgreen: #7CBC4B;
  $ddgreen-light: #8dc457;
  $ddgreen-dark: #669f42;
  $ddgreen-dark2: #5f9340;

  $ddgray: #4a4f54;
  $ddgray2: #41464c;
  $ddgray3: #383c41;
  $ddgray4: #2e3136;
  $ddgray5: #23262b;

  $ddlight: #97a3ae;
  $ddlight2: #d8dfe1;
  $ddlight3: #f7f7f7;

  // $navbar-item-color: $ddgray!important;
  // $navbar-dropdown-arrow: $ddgray!important;

  $footer-background-color: $ddgray;
  $footer-color: $ddlight2;
  $footer-padding: 2rem 1.5rem 2rem;

  //$family-primary: 'Poppins', sans-serif;
  //$family-primary: 'Roboto', sans-serif;

  // Setup $colors to use as bulma classes (e.g. 'is-twitter')
  $colors: (
      "white": ($white, $black),
      "black": ($black, $white),
      "light": ($light, $light-invert),
      "dark": ($dark, $dark-invert),
      "primary": ($primary, $primary-invert),
      "info": ($info, $info-invert),
      "success": ($success, $success-invert),
      "warning": ($warning, $warning-invert),
      "danger": ($danger, $danger-invert),
      "mydark": ($mydark, $white),
      "mylight": ($mylight, $black),
      "filter": ($filter, $white),
      "ddportable": ($ddportable, $white),
      "ddmodular": ($ddmodular, $white),
      "ddcustom": ($ddcustom, $white),
      "ddgraphic": ($ddgraphic, $white),
      "ddproject": ($ddproject, $white),    
      "ddgreen": ($ddgreen, $white),
      "ddgreen-light": ($ddgreen-light, $white),
      "ddgreen-dark": ($ddgreen-dark, $white),
      "ddgreen-dark2": ($ddgreen-dark2, $white),
      "ddgray": ($ddgray, $white),
      "ddgray2": ($ddgray2, $white),
      "ddgray3": ($ddgray3, $white),
      "ddgray4": ($ddgray4, $white),
      "ddgray5": ($ddgray5, $white),
      "ddlight": ($ddlight, $white),
      "ddlight2": ($ddlight2, $ddgray),
  );

  // Links
  $link: $primary;
  $link-invert: $primary-invert;
  $link-focus-border: $primary;

  $input-icon-color:$ddlight;
  $input-icon-active-color:$ddgray;

  .ddseparator{
    max-width:80px;
    border-top: 2px solid $ddgreen;
  }

  .sidebar-button{
    position: fixed;
    top: 25px;
    right:16px;
    z-index:40;
    cursor: pointer;
    display: none;
  }

  @media screen and (max-width: 1023px) {
     .sidebar-button{
       display:unset;
    }
  }

  //nav icon
  #nav-icon3{
  width: 25px;
  height: 30px;
  position: relative;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #7CBC4B;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 7px;
}

#nav-icon3 span:nth-child(4) {
  top: 14px;
}

#nav-icon3.open span:nth-child(1) {
  top: 7px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 7px;
  width: 0%;
  left: 50%;
}

.header_gr {
    background: #000;
    // background: linear-gradient(45deg,#6d73ff 10%,#1dbdfa,#15f2d6,#ffd900,#f043b1,#0f92ff,#20e100);
    background: linear-gradient(45deg,#7CBC4B 10%,#0f92ff,#f043b1,#ffd900,#15f2d6,#1dbdfa,#6d73ff); 
    background-size: 400% 400%;
    -webkit-animation: Gradient 140s ease infinite;
    -moz-animation: Gradient 140s ease infinite;
    animation: Gradient 140s ease infinite;
}
@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.header-title{
    color: #fff;
    font-size: 4rem;
    font-family: 'Bebas Neue';    
}
.header-subtitle{
    color: #fff;
    font-size: 2rem;   
}
//end nav icon



$card-header-background-color: $primary;

.card-header-image{
  background-color: #0f92ff!important;
}

.card-header-stats{
  background-color: #0f92ff!important;
}

// .card-header-actions{
//   background-color: #0f92ff!important;
// }

$card-header-color:$ddlight3;

$card-header-padding: 30px 20px;

.card-header-title{
  font-size: 1.6rem;
  text-transform: uppercase;

}

.card-footer{
  padding: 20px;
  flex-shrink: 0;
  justify-content: flex-end;
}

.card-footer-sample{
  padding: 0px!important;
  flex-shrink: unset;
  justify-content: unset;
}

.card-footer .button:not(:last-child) {
    margin-right: .5em;
}

.content li + li {
     margin-top: 0em!important; 
}

.content ul {
    margin-left: 0em!important;
    margin-top: 0em!important;
}


.link-bt{
  cursor: pointer;  
}
.link-red{
  color:#ed3a25;
}
.link-green{
  color:#7CBC4B;
}

.delete-bt, .edit-bt{
  color:#c9c9c9;
  cursor: pointer;
}
.delete-bt:hover{
  color: #f14668;
}
.edit-bt:hover{
  color: #7CBC4B;
}

  // Import Bulma and Buefy styles
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
</style>
