<template>
  <div class="card mb-4">
    <div class="price-tag">
      {{item.price.data}}
    </div>
    <div class="card-image">
      <figure
        class="imgconteiner"
        v-bind:class="{
          imgvisible: imgLoaded
        }"
      >
        <img 
          :src="$env.imagesURL + item.image.name"
          alt=""
          v-show="imgLoaded"
          @load="imgLoaded = true"
        />
      </figure>
      <div class="has-text-centered imgpreloader" v-show="!imgLoaded">
        <i class="fas fa-circle-notch fa-spin fa-2x"></i>
      </div>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <a @click="showDetails()" class="dditemtitle">{{
            item.name.data
          }}</a>
          <div class="ddseparator mt-2"></div>         
        </div>
      </div>
      <div class="content">
        <div class="tags" v-if="tags">
          <span v-for="(tag,index) in tags" :key="tag+index">
            <a class="tag is-light" v-html="tag"></a>
          </span>
        </div>
      </div>
    </div>
    <footer class="card-footer card-footer-sample">
      <a
        class="card-footer-item has-text-danger card-bt"
        >Details</a
      >
      <a
        class="card-footer-item has-text-success card-bt"
        >Buy Gift</a
      >
    </footer>
  </div>
</template>

<script>

export default {
  name: "itemEditSample",
  props: {
    indata: Object
  },
  data() {
    return {
      imgLoaded: false
    };
  },
  methods: {
    // showDetails() {
    //   this.$store.commit("SET_ITEMDATA", this.indata);
    //   this.$store.commit("SHOW_MODAL", { modal: "itemShow", state: true });
    // }
  },
  beforeMount() {
    //console.log("indata:" + this.indata.id);
  },
  computed: {
    item: {
      get: function() {
        return this.indata;
      }
    },
    tags: {
      get: function() {
        return this.indata.tags.data;
      }
    },
  }
}; //end export
</script>

<style scoped>
.dditemtitle{
  font-size:1.3rem;
  color: #4a4f54;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
}
.iconmargin {
  margin-right: 5px;
}
.tags span {
  margin-right: 5px;
}
.imgconteiner {
  cursor: pointer;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
.imgvisible {
  opacity: 1;
}
.imgpreloader {
  padding: 100px;
  color: #79b943;
}
.card-bt{
  font-weight: 600!important;
}
/* .equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.equal-height .card-content {
  flex: auto;
}
.card-footer {
  margin-top: auto;
} */
.card{
  position:relative;
}
.price-tag{
  position: absolute;
  right:-2px;
  top:10px;
  color: #ffffff;
  font-size: 1.3rem;
  font-family: 'Bebas Neue', cursive;
  padding:5px 10px 5px 10px;
  z-index:1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: -2px 3px 4px 0px rgba(90, 141, 49, 0.5);
  border-top: 1px solid rgb(183, 228, 146);
  border-bottom: 1px solid rgb(101,156,56);
  background: linear-gradient(90deg, 
  rgb(101,156,56) 0%,
  rgb(121,185,67) 4%,
  rgb(138, 202, 86) 80%,
  rgb(127, 189, 77) 94%,  
  rgb(183, 228, 146) 95%,
  rgb(183, 228, 146) 100%
  );
}
</style>
