<template>
<div>

                  <div class="add-item-box my-5">
        
                  <b-field
                      :type="form.name.status"
                      :message="form.name.message"
                  >
                    <div class="control" v-if="editID">
                      <b-button @click="resetForm()" icon-left="times-circle"> Cancel</b-button> 
                    </div>
                    <b-input v-model="form.name.data" placeholder="Group Name" type="text" expanded></b-input>
                    <div class="control" v-if="!editID">
                      <b-button @click="addItem()" type="is-primary" icon-left="plus-circle" :loading="isSaving"> Add Group</b-button> 
                    </div>
                    <div class="control" v-if="editID">
                      <b-button @click="updateItem()" type="is-info" icon-left="save" :loading="isSaving"> Save Group</b-button> 
                    </div>
                  </b-field>
                </div>

       <div class="item-box" v-if="!hasGroups">
                      No Menu Groups
                    </div>

                <draggable v-if="hasGroups" v-model="groupsData" v-bind="dragOptions" group="groups" @start="drag=true" @end="drag=false" :move="checkMove" handle=".handle">
                   <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                  <div v-for="item in groupsData" :key="item.id">
                    <div class="item-box level mb-5" :class="[selectedGroup == item.id ? 'item-box-active':'']">
                      <div class="level-left">
                        <span class="has-text-weight-bold clickable" @click="selectGroup(item.id)">{{item.name}}</span>
                      </div>
                      <div class="level-right">
                        <div @click="editItem(item.id)" class="edit-bt mr-2">
                          <b-icon icon="edit" size="is-medium"></b-icon>
                        </div>
                        <div @click="warningDelete(item.id)" class="delete-bt mr-2">
                          <b-icon icon="trash" size="is-medium"></b-icon>
                        </div>
                        <div class="handle">
                          <b-icon icon="grip-lines" size="is-medium" type="is-info" class="dragable"></b-icon>
                        </div>
                      </div>                   
                    </div>
                  </div>
                   </transition-group>
                </draggable>

                <div v-if="showSavePos" class="my-5 buttons save-reorder">
                  <b-button @click="resetOrder()" icon-left="redo"> Reset Order</b-button> 
                  <b-button @click="saveOrder()" type="is-info" icon-left="save" :loading="isSavingPos"> Save new Order</b-button>           
                </div>

</div>


</template>

<script>
import draggable from "vuedraggable";
import toast from "@/mixins/toast";
export default {
  name: 'manageGroup',
  components: {
    draggable
  },
  mixins: [toast],
  data() {
    return{
      drag:false,
      groupsData: null,     
      hasGroups: false,
      selectedGroup: null,
      showSavePos: false,
      editID: null,
      isSaving: false,
      isSavingPos: false,
      form:{
        name:{
          data: null,
          message: null,
          status: null
        },
      },     
    }
  },
  methods:{
    selectGroup(id){
      this.selectedGroup = id;
      console.log("selectGroup: "+id);
      this.$store.commit("setGroup", id);
    },
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    loadGroups() {
      this.groupsData = null;
      this.axios
        .post(`${this.$env.api}/manage_groups.php`, {
          token: localStorage.gshopToken,
          action: "getList"
        })
        .then(response => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.hasGroups = true;
            this.groupsData = response.data.items;
          } else {
            this.hasGroups = false;
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasGroups = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    checkMove(){
      console.log("did move group");
      this.showSavePos = true;
      console.log(this.groupsData)
    },
    editItem(id){
      console.log("Edit id:"+id);
      this.editID = id;
      this.form.name.data = this.groupsData.find(item => item.id === id).name;
    },
    addItem(){
      if (this.formCheck("name")){
        this.axios
          .post(`${this.$env.api}/manage_groups.php`, {
            token: localStorage.gshopToken,
            action: "addItem",
            name: this.form.name.data
          })
          .then(response => {
            console.log(response.data);
            this.isLoading = false;
            if (response.data.success) {
              this.toast(response.data.message, "is-success");
              this.loadGroups();
              this.resetForm();
            } else {
              this.toast(response.data.message, "is-danger");
            }
          })
          .catch(error => {
            this.hasGroups = false;
            console.log(error);
            this.toast(error, "is-danger");
            //this.errored = true;
          });
        //.finally(() => (this.loading = false));
      }
    },
    warningDelete(id) {
      this.$buefy.dialog.confirm({
        title: "Deleting Menu Group",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteItem(id)
      });
    },
    deleteItem(id){
        this.axios
          .post(`${this.$env.api}/manage_groups.php`, {
            token: localStorage.gshopToken,
            action: "deleteItem",
            itemID: id
          })
          .then(response => {
            if (response.data.success) {
              this.loadGroups();
              this.toast(response.data.message, "is-success");
            } else {
              this.toast(response.data.message, "is-danger");
            }
          })
          .catch(error => {
            console.log(error);
            this.toast(error, "is-danger");
            //this.errored = true;
          });
        //.finally(() => (this.loading = false));
    },
    resetForm(){
      this.form.name.data = null;
      this.form.name.message = null;
      this.form.name.status = null; 
      this.editID = null;
    },
    resetOrder(){
      this.groupsData = this.groupsData.sort((a, b) => a.pos - b.pos);
      this.showSavePos = false;
    },
    saveOrder(){
      for(let i = 0; i<this.groupsData.length; i++){
        this.groupsData[i].pos = i+1;
        this.updatePos(this.groupsData[i].id,this.groupsData[i].pos);
      }
      this.showSavePos = false;
    },
    updatePos(id,pos){
        this.isSavingPos = true;
        this.axios
          .post(`${this.$env.api}/manage_groups.php`, {
            token: localStorage.gshopToken,
            action: "updatePos",
            itemID: id,
            pos: pos
          })
          .then(response => {
            if (response){
              this.isSavingPos = false;              
            }
          })
        .catch(error => {
          this.isSavingPos = false;
          this.toast(error, "is-danger");
        });
    },    
    updateItem(){
      if (this.formCheck("name")){
        this.axios
          .post(`${this.$env.api}/manage_groups.php`, {
            token: localStorage.gshopToken,
            action: "updateItem",
            itemID: this.editID,
            name: this.form.name.data
          })
          .then(response => {
            this.isSaving = false;
            if (response.data.success) {
              this.toast(response.data.message, "is-success");
              this.resetForm();
              this.loadGroups();
            } else {
              this.toast(response.data.message, "is-danger");
            }
          })
        .catch(error => {
          this.isSaving = false;
          this.toast(error, "is-danger");
        });
      }
    }

  },
  mounted(){
    this.loadGroups();
  },
  computed:{
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  }
}
</script>

<style scoped>
  .item-box{
    padding: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
  }

  .item-box-active{
    border: 1px solid #7CBC4B;
    background: #f2ffe8;
  }

  .add-item-box{
    padding: 15px;
    border: 1px dashed #dfdfdf;
    border-radius: 5px;
    background: #f7f7f7;
  }

  .flip-list-move{
    transition: transform 0.5s;
  }

  .clickable{
    cursor: pointer;
  }

  .dragable{
    cursor: grab;
  }

  .dragable:active{
    cursor: grabbing;
  }

  .save-reorder{
    justify-content: flex-end;
  }
</style>