export default {
	methods: {
		toast(message,type) {
			this.$buefy.toast.open({
			duration: 5000,
            message: message,
            position: 'is-bottom',
			type: type
			})
		},
	}
}