<template>
  <div class="manage">
      <section class="container">
        <div class="mt-3">
          <div class="card">

            <header class="card-header">
              <div class="card-header-title">
                <i class="fas fa-list mr-2"></i> Manage Items
              </div>
            </header>

            <div class="card-content">
              <div class="content">
                <ManageList />
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
</template>

<script>
import ManageList from "@/components/ManageList.vue";
export default {
  name: 'items',
  components: {
    ManageList
  },
  data() {
    return{}
  }
}
</script>